import React from 'react'
import { NotFoundView } from '@views'
import { Seo } from '@atoms'

export const Head = () => {
  return <Seo title={'Not Found'} />
}

const NotFoundPage = () => {
  return <NotFoundView />
}

export default NotFoundPage
